<template>
  <div class="training-user">
    <div class="title">
      <el-input placeholder="手机号码快捷查询" v-model="input3" class="input-with-select" @input="search(input3)" >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <el-card class="box-card">
      <el-table ref="multipleTable" :data="tableData" stripe style="width: 100%" >
        <el-table-column prop="id" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="real_name" label="用户名称" align="center">
        </el-table-column>
        <el-table-column prop="sex" label="性别" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="dizhi" label="详细地址" align="center">
        </el-table-column>
        <el-table-column prop="job" label="职务" align="center">
        </el-table-column>
        <el-table-column prop="status_zh" label="审核状态" align="center">
        </el-table-column>
        <el-table-column prop="slot" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="testRecords(scope.$index, scope.row)" >考试记录</el-button >
            <el-button type="text" v-if="scope.row.status == 0" @click="addtopicVisible(scope.$index, scope.row)" >去审核</el-button>
            <el-button type="text" class="delete" v-if="scope.row.status == 0" @click="refused(scope.$index, scope.row)">不通过</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>确定通过审核？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="upStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <div class="pagination">
      <pagination @currentChange="handleCurrentChangeExp" @prevClick='prevClick' @nextClick='nextClick' :startPage='startPage' :total='totalExp'></pagination>
    </div>
  </div>
</template>

<script>
import { trainerList ,trainerVerify } from '@/api/exam.js'
export default {
  data () {
    return {
      tableData: [],
      input3: '',
      status: null,
      dialogVisible:false,
      rowList:{},
      startPage:1,
      totalExp:null
    }
  },
  created () {
    this.getUserList(this.startPage)
  },
  methods: {
    // 获取列表
    async getUserList (pages) {
      const res = await trainerList({ page: pages })
      this.tableData = res.data.result.list
      this.totalExp = res.data.result.count
    },
    async search (msg) {
      const obj = {
        phone: msg,
        page: 1
      }
      const res = await trainerList(obj)
      if(res.data.code == 1) {
        this.tableData = res.data.result.list
      }
    },
    addtopicVisible (index, row) {
      this.dialogVisible = true
      this.rowList = row
    },
    async upStatus() {
      const obj = {
        id:this.rowList.id,
        status:1 // 审核通过
      }
      const res = await trainerVerify(obj)
      if(res.data.code == 1) {
        this.$message.success('审核通过！')
        setTimeout(() => {
          this.dialogVisible = false
          this.getUserList(this.startPage)
        }, 500);
      }
    },
    // 审核不通过
    async refused(index,row) {
      const obj = {
        id:row.id,
        status:2 // 审核不通过
      }
      const res = await trainerVerify(obj)
      if(res.data.code == 1) {
        this.$message.success('审核不通过！')
        setTimeout(() => {
          this.getUserList(this.startPage)
        },500);
      }
    },
    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    // 考试记录
    testRecords(index,row) {
      this.$router.push({
        path:'./training-kp',
        query:{
          trainer_id:row.id
        }
      })
    },
    handleCurrentChangeExp(size) {
      this.getUserList(size)
    },
    prevClick(size) {
      this.getUserList(size)
    },
    nextClick(size) {
      this.getUserList(size)
    },
  }
}
</script>

<style lang="less" scoped>
.training-user {
  padding: 20px 0;
  .title {
    text-align: right;
    padding-bottom: 20px;
    .input-with-select {
      width: 300px;
    }
  }
  .pagination{
    text-align: right;
    margin-top: 20px;
  }
  .delete {
    color: #f6423b;
  }
}
</style>
